import { createSlice, PayloadAction } from "@reduxjs/toolkit";
type State = {
  groups: string[];
  userName: string;
  selectedShow?: any;
  editableShows: any[];
};

const initialState: State = {
  groups: [],
  userName: "",
  selectedShow: null,
  editableShows: [],
};

const User = createSlice({
  name: "common",
  initialState,
  reducers: {
    setGroups(state, action: PayloadAction<string[]>) {
      state.groups = action.payload;
    },
    setEditableShow(state, action: PayloadAction<string[]>) {
      if (Array.isArray(action.payload)) {
        state.editableShows = action.payload;
      }
    },
    setShow(state, action: PayloadAction<any>) {
      state.selectedShow = action.payload;
    },
    setUserName(state, action: PayloadAction<string>) {
      state.userName = action.payload;
    },
  },
});

export default User;
