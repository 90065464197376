import { createSlice, PayloadAction } from "@reduxjs/toolkit";
type CommonType = {
  loading: boolean;
  error: any;
};

const initialState: CommonType = {
  loading: false,
  error: null,
};

const Common = createSlice({
  name: "common",
  initialState,
  reducers: {
    fetchStart(state) {
      state.loading = true;
    },
    fetchSuccess(state) {
      state.loading = false;
      state.error = null;
    },
    fetchFailure(state, action: PayloadAction<Error>) {
      state.error = action.payload;
      state.loading = false;
    },
    resetError(state) {
      state.error = null;
    },
  },
});

export default Common;
