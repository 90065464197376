import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "theme";
import store from "./apps/store";
import { Provider } from "react-redux";
// import "assets/css/normalize.css";

export default function TopLayout(props: Props) {
  return (
    <Provider store={store}>
      <div id="fb-root"></div>

      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <link
          href="https://use.fontawesome.com/releases/v5.12.0/css/all.css"
          rel="stylesheet"
        />
        <script
          async={true}
          defer={true}
          crossorigin="anonymous"
          src="https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v14.0&appId=283037666106215&autoLogAppEvents=1"
          nonce="CfkmZ9sR"
        ></script>
      </Helmet>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </Provider>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
