import { useSelector, TypedUseSelectorHook } from "react-redux";
import { combineReducers } from "@reduxjs/toolkit";
import common from "features/common/commonSlice";
import user from "features/user/userSlice";
const rootReducer = combineReducers({
  common: common.reducer,
  user: user.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
